

.app__about {
  flex: 1;
  width: 100%;
  flex-direction: column;
}

.app_about-div {
  flex-direction: column;
  @media (min-width: 900px) {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .app_about-head {
    padding-right: 5rem;

    @media (max-width: 900px) {
      padding: 0
    }

    .deco {
      opacity: 0.45;

      @media (max-width: 960px){
        display: none;
      }
    }
    .head-text {
      margin: none;
      // @media (min-width: 960px){
      //   position: relative;
      //   bottom: 20rem;
      //   color: white;
      // }
      // span {     
      //   @media (min-width: 960px) {
      //     color: white
      //   }
      // }
    }
  }

  .app__profiles {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 2rem;
  }
}

.app__profile-item {
  width: 190px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin: 2rem;

  img {
    width: 100%;
    height: 170px;
    border-radius: 15px;
    object-fit: cover;
  }

  @media screen and (min-width: 2000px) {
    width: 370px;
    margin: 2rem 4rem;

    img {
      height: 320px;
    }
  }
}

.deco {

}