@import url('https://fonts.googleapis.com/css2?family=Fugaz+One&family=Montserrat:wght@500;700;900&family=Titillium+Web:ital,wght@0,200;0,400;0,700;0,900;1,400&display=swap');

// font-family: 'Fugaz One', cursive;
// font-family: 'Montserrat', sans-serif;
// font-family: 'Titillium Web', sans-serif;

#home {
  position: relative;
  background: url('../../assets/sanFran.jpeg') center no-repeat;
  background-size: cover;
  background-position: center;
  background-repeat: repeat;

  // disable the default padding styles
  .app__wrapper {
    padding: 0;

    .copyright {
      display: none;
    }
  }
}

.app__header {

  
  flex: 1;
  width: 100%;
  height: 75vh;
  flex-direction: row;

  padding: 6rem 2rem 0rem;

  @media screen and (min-width: 2000px) {
    padding-top: 8rem;
  }

  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }

  @media screen and (max-width: 450px) {
    padding: 6rem 1rem 2rem;
  }
}

.app__header-info {
  // flex: 0.65;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 50%;

  margin: 0 2rem;

  @media screen and (max-width: 1200px) {
    // width: 100%;
    // margin-right: 0rem;
  }

  button {
    background: var(--primary-color);
    color: black;
    border-radius: 5px;
    border: none;
    padding:10px 15px;
    cursor: pointer
  }
}

.app__header-badge {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  background-color: #1e212d6e;
  padding: 5px 15px;
  text-align: center;

  .header-h1 {
    font-family: 'Montserrat', sans-serif;
    color: #fff3e6 ;
    font-size: 60px;
    text-align: center;
    // margin-bottom: 10px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;

    @media screen and (max-width: 550px) {
      font-size: 50px;
    }
  }

  .header-p {
    color: white;
    font-size: 32px;  
    font-family: 'Titillium Web', sans-serif;
  }


}

.app__header-circles {
  flex: 0.75;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;font-family: 'Montserrat', sans-serif;
  height: 100%;
  margin-left: 1rem;

  div {
    width: 100px;
    height: 100px;
    border-radius: 50%;

    background: var(--white-color);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);

    img {
      width: 60%;
      height: 60%;
    }
  }

  // circles with different height & width

  div:nth-child(1) {
    width: 100px;
    height: 100px;
  }

  div:nth-child(2) {
    margin: 1.75rem;
    width: 150px;
    height: 150px;
  }

  div:nth-child(3) {
    width: 70px;
    height: 70px;
  }

  @media screen and (min-width: 2000px) {
    div:nth-child(2) {
      width: 400px;
      height: 400px;
    }

    div:nth-child(3) {
      width: 170px;
      height: 170px;
    }

    div:nth-child(1) {
      width: 200px;
      height: 200px;
    }
  }

  @media screen and (max-width: 1200px) {
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 0;

    div {
      margin: 1rem;
    }
  }
}

.app__header-img {
  flex: 1;
  height: 100%;

  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  position: relative;

  img {
    width: 100%;
    object-fit: contain;
    z-index: 1;
  }

  .overlay_circle {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    width: 100%;
    height: 90%;
  }

  @media screen and (max-width: 1200px) {
    margin: 2rem 0;
  }
}